import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getCountries, getCountryCallingCode, Country } from 'react-phone-number-input';
import { Item } from './form-control/DropdownDefaultComponents';
import DropdownSelect from './form-control/DropdownSelect';
import flags from 'react-phone-number-input/flags';
import { languages } from '../form/action-types/phone-number/Languages';
import i18next from 'i18next';
import { EventSystem } from '../../events/EventSystem';
import { Option } from '../Option';
import { SupportedLanguage } from '../../types/Languages';

type CountryCodeSelectProps = {
  value: Country | null;
  onChange: (value: Country) => void;
  disabled?: boolean;
  menuPosition?: 'right' | 'left';
  flagOnly?: boolean;
  className?: string;
  label?: string;
};

const CountrySelectedRenderer: FC<{ item: Item; flagOnly?: boolean }> = (props) => {
  const { item, flagOnly } = props;
  const flag = useMemo(() => flags[item.id as Country], [item.id]);
  if (flag) {
    return (
      <div className="flex flex-nowrap items-center whitespace-nowrap [&_svg]:w-8 [&_svg]:flex-shrink-0">
        {flag({ title: item.text })} {!flagOnly && <>&nbsp;+{getCountryCallingCode(item.id as Country)}</>}
      </div>
    );
  } else {
    return <>{getCountryCallingCode(item.id as Country)}</>;
  }
};

const CountryListRenderer: FC<Item> = (props) => {
  const flag = useMemo(() => flags[props.id as Country], [props.id]);
  if (flag) {
    return (
      <div className="flex flex-nowrap items-center whitespace-nowrap [&_svg]:w-8 [&_svg]:flex-shrink-0">
        {flag({ title: props.text })}
        <>
          &nbsp; +{getCountryCallingCode(props.id as Country)} {props.text}
        </>
      </div>
    );
  } else {
    return <>{getCountryCallingCode(props.id as Country)}</>;
  }
};

const CountryCodeSelect: FC<CountryCodeSelectProps> = (props) => {
  const { value = 'DE', onChange, disabled, menuPosition = 'right', flagOnly = false, className, label } = props;

  const countriesList = useCallback(
    (languageCode: SupportedLanguage = i18next.language) =>
      getCountries()
        .map((country) => ({ id: country, text: languages[languageCode][country as Country], value: country }))
        .sort((a, b) => {
          return a.text.toUpperCase().localeCompare(b.text.toUpperCase());
        }),
    [],
  );

  const [countries, setCountries] = useState<Option<Country, Country>[]>(countriesList());

  useEffect(
    function langaugeChanged() {
      const handler = (languageCode: SupportedLanguage) => {
        setCountries(countriesList(languageCode));
      };

      EventSystem.listen('language-changed', handler);
      return () => {
        EventSystem.stopListening('language-changed', handler);
      };
    },
    [countriesList],
  );

  const searchFilter = useCallback((item: Item, search: string) => {
    const lowerSearch = search.toLocaleLowerCase();

    return (
      item.text.toLocaleLowerCase().indexOf(lowerSearch) > -1 ||
      ('+' + getCountryCallingCode(item.id as Country).toLocaleLowerCase()).indexOf(lowerSearch) > -1
    );
  }, []);

  return (
    <DropdownSelect
      customSearchFilter={searchFilter}
      customListRenderer={CountryListRenderer}
      customSingleValueRenderer={(item) => <CountrySelectedRenderer item={item} flagOnly={flagOnly} />}
      value={countries.find((x) => x.id === value)}
      options={countries}
      onChange={(item: Item) => onChange(item.id as Country)}
      disabled={disabled}
      menuPosition={menuPosition}
      className={className}
      label={label}
    />
  );
};

export default CountryCodeSelect;
