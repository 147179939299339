import en from 'react-phone-number-input/locale/en.json';
import de from 'react-phone-number-input/locale/de.json';
import Locale from 'react-phone-number-input/locale/en.json';
import { SupportedLanguage } from '../../../../types/Languages';

type Languages = Record<SupportedLanguage, Locale>;

export const languages: Languages = {
  en: en,
  de: de,
};
